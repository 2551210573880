import { richText, stripRichTextWrapperTag } from '../../utils/html';

export default ({ primary }) => {
  const {
    iframe_url,
    iframe_text,
    iframe_fullwidth,
    iframe_id,
    collect_gclid,
  } = primary || {};

  return {
    iframeTitle: stripRichTextWrapperTag(primary.iframe_title),
    iframeText: richText(iframe_text),
    iframeId: iframe_id,
    iframeUrl: iframe_url,
    fullWidth: iframe_fullwidth,
    collect_gclid,
  };
};
